.video-library-container {
  height: 100vh;
  position: relative;
  background-color: #2c3333;
}

.video-library-heading {
  text-align: center;
  color: #e7f6f2;
  padding: 2rem;
}

@media only screen and (max-width: 600px) {
  .video-library-heading {
    padding-top: 100px;
  }
}

.video-library-center {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
