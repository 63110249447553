.register-container {
  height: 90vh;
  position: relative;
  background-color: #e7f6f2;
  margin-top: 4rem;
}

@media only screen and (max-width: 600px) {
  .register-container {
    margin-top: 0rem;
  }
}

.register-center {
  border-radius: 2rem;
  width: 75%;
  box-shadow: 20px 20px 30px black;
  padding: 1rem;
  background-color: #a5c9ca;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .register-center {
    top: 60%;
  }
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.form {
  width: 90%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.register-btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.register-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.register-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.cancel-register {
  margin-top: 1rem;
  color: #2c3333;
}

.cancel-register:hover {
  font-weight: 700;
  border-bottom: #2c3333 solid 1px;
}
