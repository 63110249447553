.event-header {
  background-color: #e7f6f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  /* border-bottom: 1px solid #2c3333; */
  margin-bottom: none;
}

.event-header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-header ul li {
  margin-left: 20px;
}

.event-header ul li a {
  display: flex;
  align-items: center;
}

.event-header ul li a:hover {
  color: #777;
}

.event-header ul li a svg {
  margin-right: 5px;
}

.event-logo {
  border-radius: 50%;
  margin: 0px;
  padding: 0ox;
}

.event-logo:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.event-logo:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.event-logo-size {
  height: 50px;
}

.event-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 1rem;
  background: #2c3333;
  color: #e7f6f2;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.event-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.event-btn svg {
  margin-right: 8px;
}
