.heading-home {
  font-size: 4rem;
}

@media only screen and (max-width: 600px) {
  .heading-home {
    padding-top: 4rem;
  }
}

.home-container {
  padding-top: 100px;
}

@media only screen and (max-width: 600px) {
  .home-container {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .heading-home {
    font-size: 3rem;
  }
}

.quote-sm {
  position: relative;
  font-size: x-small;
  bottom: 10px;
}

.image-container {
  width: 100%;

  min-height: 520px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-image: linear-gradient(
      to top,
      rgba(231, 246, 242, 0.52),
      rgba(44, 51, 51, 0.73)
    ),
    url('https://images.unsplash.com/photo-1593810450979-79e8584f76b9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.blue-light {
  color: #e7f6f2;
}

.bottom {
  position: relative;
  top: 150px;
  font-weight: 600;
}

.bottom p {
  font-size: 15px;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

@media only screen and (max-width: 600px) {
  .bottom {
    top: 100px;
  }
}

@media only screen and (max-width: 400px) {
  .bottom {
    top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .bottom p {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.videos-bottom {
  position: relative;
  top: 150px;
  font-weight: 600;
  font-size: 30px;
}

@media only screen and (max-width: 600px) {
  .videos-bottom {
    top: 70px;
  }
}

.left {
  position: relative;
  right: 200px;
  top: 100px;
  font-weight: 600;
  font-size: 30px;
}

@media only screen and (max-width: 600px) {
  .left {
    right: 0px;
    top: 70px;
  }
}

.icon-banner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  background: #a5c9ca;
  padding: 10px 10px;

  text-align: center;
}

.icon-banner img {
  width: 60px;
  height: 60px;
  border-radius: 1rem;
  background-color: #e7f6f2;
  padding: 5px;
  box-shadow: 20px 20px 30px black;
  color: #2c3333;
}

.icon-banner img:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.orange {
  color: #e8902b;
  font-size: 50px;
}

.icon-animated {
  animation: moveInIcon 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInIcon {
  0% {
    opacity: 0;
    transform: scaleX(50px);
  }

  100% {
    opacity: 1;
    transform: scaleX(0px);
  }
}

.image-container-classes {
  width: 100%;

  min-height: 520px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-image: linear-gradient(
      to top,
      rgba(231, 246, 242, 0.52),
      rgba(44, 51, 51, 0.73)
    ),
    url('https://images.unsplash.com/photo-1599901860904-17e6ed7083a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.image-container-videos {
  width: 100%;

  min-height: 520px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-image: linear-gradient(
      to top,
      rgba(231, 246, 242, 0.52),
      rgba(44, 51, 51, 0.73)
    ),
    url('https://images.unsplash.com/photo-1577344718665-3e7c0c1ecf6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.classes-p {
  position: relative;
  margin-top: 4rem;
  margin-left: 2rem;
  font-weight: 600;
  font-size: 30px;
  background-color: #a5c9ca;
  color: #2c3333;
  max-width: 400px;
  min-height: 250px;
  border-radius: 2rem;
  padding: 10px;
  box-shadow: 20px 20px 30px black;
}

.btn {
  padding: 10px;
  border: none;
  border-radius: 1rem;
  background: #2c3333;
  color: #e7f6f2;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* --------------------Button Animation------------------------------ */
.btn-single-class {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
}

.btn-animated:link,
.btn-animated:visited {
  box-shadow: 20px 20px 30px black;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}

.btn-animated:hover {
  transform: translateY(-3px);
  box-shadow: 20px 20px 30px black;
}

.btn-animated:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.btn-white {
  background-color: #e7f6f2;
  color: #2c3333;
}

.btn-animated::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #e8902b;
}

.btn-animated:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* -----------------------end---------------------- */

.divider-banner {
  height: 100%;
  background: #a5c9ca;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.divider-banner img {
  width: 90%;
}

.ym-tv-div {
  float: right;

  margin-top: 8rem;
  margin-right: 4rem;
  font-weight: 600;
  font-size: 30px;
  background-color: #a5c9ca;
  color: #2c3333;
  max-width: 400px;
  min-height: 250px;
  border-radius: 2rem;
  padding: 20px;
  box-shadow: 20px 20px 30px black;
}

/* ----------------------------footer------------------------- */

.footer {
  background: linear-gradient(to top, #e7f6f2, #a5c9ca);
  padding-top: 80px;
  padding-bottom: 20px;
}

/* -------------------------------------------- */

.first-column {
  color: #2c3333;
  float: left;
  width: 50%;
  padding: 20px;
  text-align: center;
  /* border: #2c3333 solid 2px;
  border-right: none; */
  height: auto;
}

@media only screen and (max-width: 530px) {
  .first-column {
    width: 100%;
  }
}

.second-column {
  text-align: center;
  color: #2c3333;
  float: left;
  width: 50%;
  padding: 20px;
  /* border: #2c3333 solid 2px;
  border-left: none; */
  height: auto;
}

@media only screen and (max-width: 530px) {
  .second-column {
    width: 100%;
  }
}

.third-column {
  margin-top: 2rem;
  background-color: #2c3333;
  color: #e7f6f2;
  float: left;
  width: 100%;
  padding: 10px;
  text-align: center;
  /* border: #2c3333 solid 2px;
  border-left: none; */
  height: auto;
}

.row {
  padding-bottom: 0;
}

.a-tag {
  color: #2c3333;
}

.a-tag:hover {
  font-weight: 700;
  border-bottom: #2c3333 solid 1px;
}

.modal {
  padding: 2rem;
  background-color: #2c3333;
  color: #e7f6f2;
}

.home-modal-btn-close {
  background: none;
  border: none;
  color: #e7f6f2;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.home-modal-btn-close:hover {
  color: #e8902b;
  transform: scale(0.98);
}
