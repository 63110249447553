.column-1 {
  float: left;
  width: 35%;
  padding: 10px;
  /* border: #2c3333 solid 2px;
  border-right: none; */
  height: 10rem;
}
.column-2 {
  float: left;
  width: 65%;
  padding: 10px;
  /* border: #2c3333 solid 2px;
  border-left: none; */
  height: 25%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.month {
  background-color: #2c3333;
  color: #e7f6f2;
  text-align: center;
  font-size: large;
  margin: 0;
}

.day-name {
  font-size: large;
  margin: 0;
  text-align: center;
}

.day-number {
  font-size: x-large;
  margin: 0;
  text-align: center;
  border-bottom: #2c3333 solid 1px;
}

.time {
  text-align: center;
  margin: 0;
  border-bottom: #2c3333 solid 1px;
}

.event-item {
  color: #2c3333;
  font-weight: bold;

  align-items: center;
  margin: 10px;
  background: #e7f6f2;
  padding: 0px;
  border-radius: 1rem;
  text-align: left;
}

.center-div {
  text-align: center;
  margin: 1rem;
}

.event-item-description {
  font-weight: 500;
}

@media only screen and (max-width: 700px) {
  .event-item-description {
    display: none;
  }
}

.event-item-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: #2c3333;
  color: #e7f6f2;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-item-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.event-item-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}
