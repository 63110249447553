.events-headings {
  color: #e7f6f2;
  font-size: 2rem;
  font-weight: 700;
}

.events-headings {
  font-weight: 700;
}

.events-container {
  padding-top: 10rem;
  height: 90vh;
  position: relative;
  background-color: #395b64;
  height: 100%;
  padding-bottom: 3rem;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .events-container {
    padding-top: 100px;
  }
}

.status-btn {
  background-color: #395b64;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 20px 0;
}

.status-query-btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 2rem;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  /* width: 100%;
  margin-bottom: 20px; */
}

.create-class {
  text-align: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e6e6e6;
}

.create-class-link {
  background-color: #a5c9ca;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  font-size: 16px;
}

.create-class-link:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.create-class-link:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.status-query-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.status-query-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}
