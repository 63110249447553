.profile-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #a5c9ca;
  color: #2c3333;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

@media only screen and (max-width: 600px) {
  .profile-header {
    padding-top: 100px;
  }
}

.change-password-link {
  font-size: medium;
  border: #2c3333 solid 2px;
  background-color: #395b64;
  color: #e7f6f2;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 1rem;
}

.change-password-link:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.change-password-link:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.email-p {
  border-bottom: #2c3333 solid 2px;
  padding-left: 3rem;
  padding-right: 3rem;
}

.margin-Bottom {
  margin-bottom: 2rem;
}

.profile-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes profile-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .profile-logo {
    animation: profile-logo-float infinite 3s ease-in-out;
  }
}

.modal-btn-close {
  font-weight: bold;
  background: none;
  border: none;
  color: #2c3333;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
}

.modal-btn-close:hover {
  color: #2c3333;
  transform: scale(1.4);
}

.modal-btn-close:active {
  transform: scale(0.8);
}

.manage-sub-btn {
  padding: 10px;
  border: none;
  border-radius: 1rem;
  background: #2c3333;
  color: #e7f6f2;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin: auto;
}

.manage-sub-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.manage-sub-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.sub-section {
  padding: 1rem;
  margin-bottom: 1rem;
}
