.delete-booking-container {
  height: 100vh;
  position: relative;
  background-color: #e7f6f2;
}

.delete-booking-center {
  box-shadow: 20px 20px 30px black;
  padding: 3rem;
  background-color: #a5c9ca;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.delete-booking-btn {
  margin: 1rem;
  width: 150px;
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-booking-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.delete-booking-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}
