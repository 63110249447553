.stripe-success {
  height: 100vh;
  padding: 70px 0;
  padding-top: 200px;
  text-align: center;
  background-color: #a5c9ca;
  color: #2c3333;
}

.stripe-success-link {
  border: #2c3333 solid 1px;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
}

.stripe-success-link:hover {
  background-color: #2c3333;
  color: #a5c9ca;
}

.stripe-success p {
  padding: 1rem;
}

.stripe-success h2 {
  padding-bottom: 2rem;
}

.stripe-success h1 {
  padding-bottom: 2rem;
}
