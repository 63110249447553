ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.show-pointer {
  cursor: pointer;
}

.header {
  width: 100%;
  z-index: 1;
  background-color: #2c3333;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* border-bottom: 1px solid #2c3333; */
  margin-bottom: none;
  position: fixed;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .header {
    display: none;
  }
}

.header-collapsed {
  display: none;
}

.header-collapsed-btn {
  background-color: #a5c9ca;
  border: none;
  cursor: pointer;
  padding: 1rem;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .header-collapsed {
    z-index: 1;

    display: flex;

    align-items: center;

    margin-bottom: none;
    position: fixed;
    top: 20px;
    left: 42%;
  }
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
  color: #a5c9ca;
}

.header ul li a:hover {
  text-shadow: 2px 2px black;
  font-weight: 700;
  transform: scale(1.2);
}

.header ul li a:active {
  text-shadow: 0.5px 0.5px black;
  transform: scale(0.8);
}

.p-header:hover {
  font-weight: 700;
  color: black;
}

.header ul li a svg {
  margin-right: 5px;
}

.nav-modal-btn-close {
  font-weight: bold;
  background: none;
  border: none;
  color: #a5c9ca;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
}

.nav-modal-btn-close:hover {
  transform: scale(1.4);
  color: #a5c9ca;
}

.nav-modal-btn-close:active {
  transform: scale(0.8);
}

.menu-header {
  background-color: #2c3333;
  padding: 1rem;
  color: #fff;
}

.menu-main {
  padding: 1rem;
  background-color: #a5c9ca;
}

.menu-main ul li {
  padding: 1rem;
}

.menu-main ul li a:hover {
  text-shadow: 2px 2px black;
  transform: scale(1.4);
}

.menu-main ul li a:active {
  text-shadow: 0.5px 0.5px black;
  transform: scale(0.8);
}

.menu-link {
  color: #2c3333;
}

.logo-size {
  height: 50px;
}
