.event-container {
  height: 100vh;
  position: relative;
  background-color: #e7f6f2;
  margin-top: 100px;
}

@media only screen and (max-width: 600px) {
  .event-container {
    margin-top: 0px;
  }
}

.event-center {
  text-align: center;
  color: #2c3333;
  border-radius: 1rem;
  width: 90%;
  box-shadow: 20px 20px 30px black;
  padding: 1rem;
  background-color: #e7f6f2;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.event-heading {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.event-heading p {
  color: #828282;
}

.bold {
  font-weight: bold;
}

.hidden-input {
  display: none;
}

.booking-btn {
  padding: 10px;
  border: none;
  border-radius: 1rem;
  background: #2c3333;
  color: #e7f6f2;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin: auto;
}

.booking-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.booking-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}

.delete-link {
  background-color: red;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
}

.delete-link:hover {
  color: black;
  background-color: white;
  border: black solid;
  font-weight: bold;
}

.form-container {
  text-align: center;
  margin-bottom: 2rem;
}

.inline-bookings {
  display: inline-block;
  width: 75%;
}

.status {
  background-color: #333;
  color: #fff;
  width: 100px;
  padding: 0 20px;
  justify-self: center;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
}

.status-new {
  background-color: green;
  color: #fff;
  border-radius: 10px;
}

.status-open {
  background-color: steelblue;
  color: #fff;
  border-radius: 10px;
}

.status-closed {
  background-color: darkred;
  color: #fff;
  border-radius: 10px;
}
.hr-space {
  margin: 1rem;
}
