.update-password-form {
  text-align: center;
  padding-top: 120px;
}

.cancel-update-password {
  margin-top: 1rem;
}

.cancel-update-password {
  color: #2c3333;
}

.cancel-update-password:hover {
  font-weight: 700;
  border-bottom: #2c3333 solid 1px;
}

.update-password-btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.update-password-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.update-password-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}
