.price-container {
  height: 100vh;
  position: relative;
  background-color: #2c3333;
}

.price-center {
  text-align: center;
  color: #2c3333;
  border-radius: 1rem;
  width: 70%;
  box-shadow: 20px 20px 30px black;
  background-color: #e7f6f2;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.price-card {
  margin: 1rem;
  border: #2c3333 solid 5px;
}

.price-image {
  width: 30%;
  display: flex;
  align-items: center;
}

.price-image img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.price-subscribe {
  flex: 1;
  margin: 2rem;
  color: #2c3333;
}

.price-subscribe p {
  margin: 1rem 0;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1px;
}

.price-btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.price-btn:hover {
  transform: scale(1.1);
  box-shadow: 20px 20px 30px black;
}

.price-btn:active {
  transform: translateY(4px);
  box-shadow: 5px 5px 5px black;
}
